.onasContainer{
    position: absolute;
    top: 2400px;
    text-align: center;
    width: 90%;
    left: 5%;
}
.onasContainer p{
    font-family: 'LemonMilklight';
    font-size: 16px;
    color: rgb(56, 56, 56);
    margin: auto;
    max-width: 740px;
    position: relative;
}
.onasContainer h2{
    font-family: 'LemonMilk';
}
@media screen and (max-width: 319px){
    .onasContainer{
        top: 2900px;
    }
}
@media screen and (min-width: 320px) and (max-width: 339px){
    .onasContainer{
        top: 2900px;
    }
}
@media screen and (min-width: 340px) and (max-width: 359px){
    .onasContainer{
        top: 2725px;
    }
}
@media screen and (min-width: 360px) and (max-width: 399px){
    .onasContainer{
        top: 2725px;
    }
}
@media screen and (min-width: 400px) and (max-width: 470px){
    .onasContainer{
        top: 2725px;
    }
}
@media screen and (min-width: 471px) and (max-width: 540px){
    .onasContainer{
        top: 2750px;
    }
}
@media screen and (min-width: 541px) and (max-width: 600px){
    .onasContainer{
        top: 2660px;
    }
}
@media screen and (min-width: 601px) and (max-width: 680px){
    .onasContainer{
        top: 2660px;
    }
}
@media screen and (min-width: 681px) and (max-width: 719px){
    .onasContainer{
        top: 2660px;
    }
}
@media screen and (min-width: 720px) and (max-width: 799px){
    .onasContainer{
        top: 2660px;
    }
}
@media screen and (min-width: 800px) and (max-width: 1023px){
    .onasContainer{
        top: 1680px;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1279px){
    .onasContainer{
        top: 1870px;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1365px){
    .onasContainer{
        top: 1880px;
    }
}
@media screen and (min-width: 1366px) and (max-width: 1599px){
    .onasContainer{
        top: 1860px;
    }
}
@media screen and (min-width: 1600px) and (max-width: 1919px){
    .onasContainer{
        top: 1587px;
    }
    .onasContainer p{
        font-size: 21px;
    }
}
@media screen and (min-width: 1920px) and (max-width: 2047px){
    .onasContainer{
        top: 1590px;
    }
    .onasContainer p{
        font-size: 21px;
    }
}
@media screen and (min-width: 2048px) and (max-width: 2559px){
    .onasContainer{
        top: 1590px;
    }
    .onasContainer p{
        font-size: 21px;
    }
}
@media screen and (min-width: 2560px) and (max-width: 3655px){
    .onasContainer{
        top: 1590px;
    }
    .onasContainer p{
        font-size: 21px;
    }
}
@media screen and (min-width: 3656px) and (max-width: 3839px){
    .onasContainer{
        top: 1590px;
    }
    .onasContainer p{
        font-size: 21px;
    }
}
@media screen and (min-width: 3840px) and (max-width: 4095px){
    .onasContainer{
        top: 1590px;
    }
    .onasContainer p{
        font-size: 21px;
    }
}
@media screen and (min-width: 4096px){
    .onasContainer{
        top: 1930px;
    }
    .onasContainer p{
        font-size: 21px;
    }
}