body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'LemonMilkLight';
  src: local('LemonMilkLight'), url(./fonts/LemonMilklight.woff) format('woff');
}
@font-face {
  font-family: 'LemonMilk';
  src: local('LemonMilk'), url(./fonts/LemonMilk.woff) format('woff');
}
@font-face {
  font-family: 'LemonMilkBold';
  src: local('LemonMilkBold'), url(./fonts/LemonMilkbold.woff) format('woff');
}