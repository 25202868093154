.onashead{
    width: 80%;
    left: 10%;
    position: absolute;
    top: 75px;
    height: 130px;
}
.onashead a{
    text-decoration: none;
}
.onashead a:active{
    color: black;
}
.onashead a:visited{
    color: black;
}
.onashead a:link{
    color: black;
}
.onashead a:hover{
    color: black;
}
.onasBlurHead{
    width: 70px;
    height: 25px;
    background-color: white;
    filter: blur(1);
    border-radius: 200px;
    opacity: 0.6;
    position: absolute;
    left: 2%;
    top: 5px;
    z-index: 700;
}
.onasHeadTextContainer{
    width: 70px;
    height: 25px;
    border-radius: 200px;
    position: absolute;
    left: 2%;
    top: 5px;
    z-index: 700;
    text-align: center;
}
.onasBlurText{
    z-index: 700;
    margin-top: 2px;
    font-family: 'LemonMilk';
    font-size: 16px;
}
.onasInfoText{
    font-family: 'LemonMilk';
    color: white;
    font-size: 16px;
    position: absolute;
    left: 3%;
    top: 43px;
}
.onasInfoTitle{
    font-family: 'LemonMilk';
    color: white;
    font-size: 16px;
    position: absolute;
    left: 3%;
    top: 20px;
}
.citajteViac{
    position: absolute;
    top: 155px;
    left: 3%;
    background-color: white;
}
.citajteViac p{
    margin: 5px;
    margin-right: 8px;
    font-family: 'LemonMilk';
    font-size: 13px;
    display: inline-block;
}
.citajteViac img{
    display: inline-block;
    position: relative;
    top: -3px;
    left: 0px;
    margin-right: 8px;
}
.telcBlur{
    position: absolute;
    width: 125px;
    height: 28px;
    background-color: white;
    top: 155px;
    left: 62%;
    filter: blur(1);
    opacity: 0.8;
    border-radius: 15px;
}
.telContainer{
    position: absolute;
    width: 125px;
    height: 28px;
    top: 155px;
    left: 62%;
    border-radius: 15px;
    text-align: center;
}
.telC{
    margin-top: 2px;
    font-size: 16px;
    font-family: 'LemonMilk';
}
@media screen and (max-width: 319px){
    .telContainer{
        left: 59%;
    }
    .telcBlur{
        left: 59%;
    }
    .citajteViac{
        left: 0px;
    }
    .onasBlurHead{
        left: 0%;
    }
    .onasHeadTextContainer{
        left: 0%;
    }
    .onasInfoTitle{
        left: 0px;
    }
    .onasInfoText{
        left: 0px;
    }
}
@media screen and (min-width: 320px) and (max-width: 339px){
    .telContainer{
        left: 59%;
    }
    .telcBlur{
        left: 59%;
    }
    .citajteViac{
        left: 0px;
    }
    .onasBlurHead{
        left: 0%;
    }
    .onasHeadTextContainer{
        left: 0%;
    }
    .onasInfoTitle{
        left: 0px;
    }
    .onasInfoText{
        left: 0px;
    }
}
@media screen and (min-width: 340px) and (max-width: 359px){
    .telContainer{
        left: 59%;
    }
    .telcBlur{
        left: 59%;
    }
    .citajteViac{
        left: 0px;
    }
    .onasBlurHead{
        left: 0%;
    }
    .onasHeadTextContainer{
        left: 0%;
    }
    .onasInfoTitle{
        left: 0px;
    }
    .onasInfoText{
        left: 0px;
    }
}
@media screen and (min-width: 400px) and (max-width: 470px){
    
}
@media screen and (min-width: 471px) and (max-width: 540px){
    
}
@media screen and (min-width: 541px) and (max-width: 600px){
    
}
@media screen and (min-width: 601px) and (max-width: 680px){
    
}
@media screen and (min-width: 681px) and (max-width: 719px){
    
}
@media screen and (min-width: 720px) and (max-width: 799px){
    
}
@media screen and (min-width: 800px) and (max-width: 1023px){
    
}
@media screen and (min-width: 1024px) and (max-width: 1279px){
    .onashead{
        width: 70%;
        left: 1.5%;
    }
    .onasBlurHead{
        left: 3%;
        top: 74px;
        width: 85px;
        height: 31px;
    }
    .onasHeadTextContainer{
        width: 70px;
        height: 25px;
        left: 30px;
        top: 74px;
    }
    .onasBlurText{
        left: 2%;
        position: absolute;
        font-size: 18px;
    }
    .onasInfoTitle{
        left: 3%;
        top: 90px;
        font-size: 24px;
    }
    .onasInfoText{
        left: 3%;
        top: 153px;
        font-size: 16px;
    }
    .telcBlur{
        width: 160px;
        height: 34px;
        left: 46%;
        top: 115px;
    }
    .telContainer{
        width: 180px;
        height: 30px;
        top: 115px;
        left: 44.3%;
    }
    .telC{
        font-size: 21px;
    }
    .citajteViac{
        top: 240px;
    }
    .citajteViac p{
        font-size: 18px;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1365px){
    .onashead{
        width: 70%;
        left: 1.5%;
    }
    .onasBlurHead{
        left: 3%;
        top: 74px;
        width: 89px;
        height: 34px;
    }
    .onasHeadTextContainer{
        width: 70px;
        height: 25px;
        left: 38px;
        top: 74px;
    }
    .onasBlurText{
        left: 2%;
        position: absolute;
        font-size: 20px;
    }
    .onasInfoTitle{
        left: 3%;
        top: 90px;
        font-size: 27px;
    }
    .onasInfoText{
        left: 3%;
        top: 153px;
        font-size: 18px;
    }
    .telcBlur{
        width: 160px;
        height: 34px;
        left: 42%;
        top: 120px;
    }
    .telContainer{
        width: 180px;
        height: 30px;
        top: 120px;
        left: 41%;
    }
    .telC{
        font-size: 21px;
    }
    .citajteViac{
        top: 253px;
    }
    .citajteViac p{
        font-size: 21px;
    }
}
@media screen and (min-width: 1366px) and (max-width: 1599px){
    .onashead{
        width: 70%;
        left: 1.5%;
    }
    .onasBlurHead{
        left: 3%;
        top: 74px;
        width: 89px;
        height: 34px;
    }
    .onasHeadTextContainer{
        width: 70px;
        height: 25px;
        left: 38px;
        top: 74px;
    }
    .onasBlurText{
        left: 2%;
        position: absolute;
        font-size: 20px;
    }
    .onasInfoTitle{
        left: 3%;
        top: 90px;
        font-size: 27px;
    }
    .onasInfoText{
        left: 3%;
        top: 153px;
        font-size: 18px;
    }
    .telcBlur{
        width: 160px;
        height: 34px;
        left: 40%;
        top: 120px;
    }
    .telContainer{
        width: 180px;
        height: 30px;
        top: 120px;
        left: 39%;
    }
    .telC{
        font-size: 21px;
    }
    .citajteViac{
        top: 253px;
    }
    .citajteViac p{
        font-size: 21px;
    }
}
@media screen and (min-width: 1600px) and (max-width: 1919px){
    .onashead{
        width: 70%;
        left: 1.5%;
    }
    .onasBlurHead{
        left: 3%;
        top: 74px;
        width: 89px;
        height: 34px;
    }
    .onasHeadTextContainer{
        width: 70px;
        height: 25px;
        left: 45px;
        top: 74px;
    }
    .onasBlurText{
        left: 2%;
        position: absolute;
        font-size: 20px;
    }
    .onasInfoTitle{
        left: 3%;
        top: 79px;
        font-size: 34px;
    }
    .onasInfoText{
        left: 3%;
        top: 153px;
        font-size: 21px;
    }
    .telcBlur{
        width: 180px;
        height: 41px;
        left: 42%;
        top: 118px;
    }
    .telContainer{
        width: 180px;
        height: 36px;
        top: 118px;
        left: 42%;
    }
    .telC{
        font-size: 24px;
    }
    .citajteViac{
        top: 283px;
    }
    .citajteViac p{
        font-size: 21px;
    }
}
@media screen and (min-width: 1920px) and (max-width: 2047px){
    .onashead{
        width: 50%;
        left: 1.5%;
    }
    .onasBlurHead{
        left: 3%;
        top: 74px;
        width: 89px;
        height: 34px;
    }
    .onasHeadTextContainer{
        width: 70px;
        height: 25px;
        left: 38px;
        top: 74px;
    }
    .onasBlurText{
        left: 2%;
        position: absolute;
        font-size: 20px;
    }
    .onasInfoTitle{
        left: 3%;
        top: 79px;
        font-size: 34px;
    }
    .onasInfoText{
        left: 3%;
        top: 153px;
        font-size: 21px;
    }
    .telcBlur{
        width: 180px;
        height: 41px;
        left: 48%;
        top: 118px;
    }
    .telContainer{
        width: 180px;
        height: 36px;
        top: 118px;
        left: 48%;
    }
    .telC{
        font-size: 24px;
    }
    .citajteViac{
        top: 273px;
    }
    .citajteViac p{
        font-size: 21px;
    }
}
@media screen and (min-width: 2048px) and (max-width: 2559px){
    .onashead{
        width: 50%;
        left: 1.5%;
    }
    .onasBlurHead{
        left: 3%;
        top: 74px;
        width: 89px;
        height: 34px;
    }
    .onasHeadTextContainer{
        width: 70px;
        height: 25px;
        left: 38px;
        top: 74px;
    }
    .onasBlurText{
        left: 2%;
        position: absolute;
        font-size: 20px;
    }
    .onasInfoTitle{
        left: 3%;
        top: 79px;
        font-size: 34px;
    }
    .onasInfoText{
        left: 3%;
        top: 153px;
        font-size: 21px;
    }
    .telcBlur{
        width: 180px;
        height: 41px;
        left: 48%;
        top: 118px;
    }
    .telContainer{
        width: 180px;
        height: 36px;
        top: 118px;
        left: 48%;
    }
    .telC{
        font-size: 24px;
    }
    .citajteViac{
        top: 273px;
    }
    .citajteViac p{
        font-size: 21px;
    }
}
@media screen and (min-width: 2560px) and (max-width: 3655px){
    .onashead{
        width: 38%;
        left: 1.5%;
    }
    .onasBlurHead{
        left: 3%;
        top: 74px;
        width: 89px;
        height: 34px;
    }
    .onasHeadTextContainer{
        width: 70px;
        height: 25px;
        left: 38px;
        top: 74px;
    }
    .onasBlurText{
        left: 2%;
        position: absolute;
        font-size: 20px;
    }
    .onasInfoTitle{
        left: 3%;
        top: 79px;
        font-size: 34px;
    }
    .onasInfoText{
        left: 3%;
        top: 153px;
        font-size: 21px;
    }
    .telcBlur{
        width: 180px;
        height: 41px;
        left: 48%;
        top: 118px;
    }
    .telContainer{
        width: 180px;
        height: 36px;
        top: 118px;
        left: 48%;
    }
    .telC{
        font-size: 24px;
    }
    .citajteViac{
        top: 273px;
    }
    .citajteViac p{
        font-size: 21px;
    }
}
@media screen and (min-width: 3656px) and (max-width: 3839px){
    .onashead{
        width: 27%;
        left: 1.5%;
    }
    .onasBlurHead{
        left: 3%;
        top: 74px;
        width: 89px;
        height: 34px;
    }
    .onasHeadTextContainer{
        width: 70px;
        height: 25px;
        left: 38px;
        top: 74px;
    }
    .onasBlurText{
        left: 2%;
        position: absolute;
        font-size: 20px;
    }
    .onasInfoTitle{
        left: 3%;
        top: 79px;
        font-size: 34px;
    }
    .onasInfoText{
        left: 3%;
        top: 153px;
        font-size: 21px;
    }
    .telcBlur{
        width: 180px;
        height: 41px;
        left: 48%;
        top: 118px;
    }
    .telContainer{
        width: 180px;
        height: 36px;
        top: 118px;
        left: 48%;
    }
    .telC{
        font-size: 24px;
    }
    .citajteViac{
        top: 273px;
    }
    .citajteViac p{
        font-size: 21px;
    }
}
@media screen and (min-width: 3840px) and (max-width: 4095px){
    .onashead{
        width: 27%;
        left: 1.5%;
    }
    .onasBlurHead{
        left: 3%;
        top: 74px;
        width: 89px;
        height: 34px;
    }
    .onasHeadTextContainer{
        width: 70px;
        height: 25px;
        left: 38px;
        top: 74px;
    }
    .onasBlurText{
        left: 2%;
        position: absolute;
        font-size: 20px;
    }
    .onasInfoTitle{
        left: 3%;
        top: 79px;
        font-size: 34px;
    }
    .onasInfoText{
        left: 3%;
        top: 153px;
        font-size: 21px;
    }
    .telcBlur{
        width: 180px;
        height: 41px;
        left: 48%;
        top: 118px;
    }
    .telContainer{
        width: 180px;
        height: 36px;
        top: 118px;
        left: 48%;
    }
    .telC{
        font-size: 24px;
    }
    .citajteViac{
        top: 273px;
    }
    .citajteViac p{
        font-size: 21px;
    }
}
@media screen and (min-width: 4096px){
    .onashead{
        width: 50%;
        left: 1.5%;
    }
    .onasBlurHead{
        left: 3%;
        top: 74px;
        width: 89px;
        height: 34px;
    }
    .onasHeadTextContainer{
        width: 70px;
        height: 25px;
        left: 38px;
        top: 74px;
    }
    .onasBlurText{
        left: 2%;
        position: absolute;
        font-size: 20px;
    }
    .onasInfoTitle{
        left: 3%;
        top: 79px;
        font-size: 34px;
    }
    .onasInfoText{
        left: 3%;
        top: 153px;
        font-size: 21px;
    }
    .telcBlur{
        width: 180px;
        height: 41px;
        left: 48%;
        top: 118px;
    }
    .telContainer{
        width: 180px;
        height: 36px;
        top: 118px;
        left: 48%;
    }
    .telC{
        font-size: 24px;
    }
    .citajteViac{
        top: 273px;
    }
    .citajteViac p{
        font-size: 21px;
    }
}