.burgerImg{
  position: fixed;
  top: 22px;
  left: 4%;
  background-color:rgb(0 82 180);
  padding: 10px;
  z-index: 199999966;
  border-radius: 5px;
  transform: scale(0.8);
}
button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}
.burgerContainer{
  width: 250px;
  height: 350px;
  border-radius: 15px;
  position: fixed;
  left: -250px;
  top: -20px;
  background-color: white;
  z-index: 199999988;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.burgerContainer[data-isOpen="true"]{
  left: 15px;
  top: 10px;
}
.burgerBlur{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  margin: 0;
  padding: 0;
  filter: blur(1);
  opacity: 0.7;
  display: none;
  background-color: black;
  z-index: 199999977;
}
.burgerBlur[data-isOpen="true"]{
  display: block;
}
.burgerClose{
  position: absolute;
  left: 210px;
  top: 15px;
  z-index: 199999999;
}
.navBottom{
  background-color: rgb(241 241 241);
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0px;
  border-radius: 0px 0px 15px 15px;
  border-color: rgb(241 241 241);
}
.fblogoNav{
  position: absolute;
  left: 43%;
  bottom: 30px;
  transform: scale(1.3);
}
.navItemsContainer{
  position: absolute;
  left: 25px;
  top: 30px;
  z-index: 199999999;
}
.navItemsContainer a{
  text-decoration: none;
  color: black;
  font-family: 'LemonMilk';
  letter-spacing: 1px;
  font-size: 18px;
}
.navItemsContainer a p{
  margin-top: 0px !important;
  margin-bottom: 9px !important;
}
.chiroLogo{
  transform: scale(0.13);
    position: absolute;
    left: -115px;
    top: -119px;
    z-index: 199999999;
    opacity: 0.7;
}
.navMobBlur{
  width: 100%;
  height: 60px;
  background-color: white;
  position: fixed;
  z-index: 199999960;
}
@media screen and (max-width: 319px){
  .burgerContainer{
    height: 250px;
    width: 185px;
  }
  .navBottom{
    height: 80px; 
  }
  .burgerClose{
    left: 150px;
  }
  .navItemsContainer a p{
    margin-bottom: 9px !important;
  }
  .navItemsContainer a{
    font-size: 14px;
  }
  .fblogoNav{
    transform: scale(1);
    bottom: 23px;
    left: 41.5%;
  }
  .closeClock{
    left: 25% !important;
  }
  .burgerImg{
    top: 8px;
  }
  .parent{
    left: 25% !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 339px){
  .burgerContainer{
    height: 250px;
    width: 185px;
  }
  .navBottom{
    height: 80px; 
  }
  .burgerClose{
    left: 150px;
  }
  .navItemsContainer a p{
    margin-bottom: 9px !important;
  }
  .navItemsContainer a{
    font-size: 14px;
  }
  .fblogoNav{
    transform: scale(1);
    bottom: 23px;
    left: 41.5%;
  }
  .closeClock{
    left: 25% !important;
  }
  .burgerImg{
    top: 8px;
  }
  .parent{
    left: 25% !important;
  }
}
@media screen and (min-width: 340px) and (max-width: 374px){
  .burgerContainer{
    height: 250px;
    width: 185px;
  }
  .navBottom{
    height: 80px; 
  }
  .burgerClose{
    left: 150px;
  }
  .navItemsContainer a p{
    margin-bottom: 9px !important;
  }
  .navItemsContainer a{
    font-size: 14px;
  }
  .fblogoNav{
    transform: scale(1);
    bottom: 23px;
    left: 41.5%;
  }
  .closeClock{
    left: 25% !important;
  }
  .burgerImg{
    top: 8px;
  }
  .langContainer{
    right: 45px;
  }
  .parent{
    left: 25% !important;
  }
}
@media screen and (min-width: 375px) and (max-width: 399px){
  .burgerContainer{
    height: 250px;
    width: 185px;
  }
  .navBottom{
    height: 80px; 
  }
  .burgerClose{
    left: 150px;
  }
  .navItemsContainer a p{
    margin-bottom: 9px !important;
  }
  .navItemsContainer a{
    font-size: 14px;
  }
  .fblogoNav{
    transform: scale(1);
    bottom: 23px;
    left: 41.5%;
  }
  .closeClock{
    left: 25% !important;
  }
  .burgerImg{
    top: 8px;
  }
  .langContainer{
    right: 45px;
  }
  .parent{
    left: 28% !important;
  }
}
@media screen and (min-width: 400px) and (max-width: 470px){
  .burgerContainer{
    height: 250px;
    width: 185px;
  }
  .navBottom{
    height: 80px; 
  }
  .burgerClose{
    left: 150px;
  }
  .navItemsContainer a p{
    margin-bottom: 9px !important;
  }
  .navItemsContainer a{
    font-size: 14px;
  }
  .fblogoNav{
    transform: scale(1);
    bottom: 23px;
    left: 41.5%;
  }
  .closeClock{
    left: 29% !important;
  }
  .burgerImg{
    top: 8px;
  }
  .langContainer{
    right: 45px;
  }
  .parent{
    left: 28% !important;
  }
}
@media screen and (min-width: 471px) and (max-width: 540px){
  .burgerContainer{
    height: 250px;
    width: 185px;
  }
  .navBottom{
    height: 80px; 
  }
  .burgerClose{
    left: 150px;
  }
  .navItemsContainer a p{
    margin-bottom: 9px !important;
  }
  .navItemsContainer a{
    font-size: 14px;
  }
  .fblogoNav{
    transform: scale(1);
    bottom: 23px;
    left: 41.5%;
  }
  .closeClock{
    left: 32% !important;
  }
  .burgerImg{
    top: 8px;
  }
  .langContainer{
    right: 45px;
  }
  .parent{
    left: 32% !important;
  }
}
@media screen and (min-width: 541px) and (max-width: 600px){
  .burgerContainer{
    height: 250px;
    width: 185px;
  }
  .navBottom{
    height: 80px; 
  }
  .burgerClose{
    left: 150px;
  }
  .navItemsContainer a p{
    margin-bottom: 9px !important;
  }
  .navItemsContainer a{
    font-size: 14px;
  }
  .fblogoNav{
    transform: scale(1);
    bottom: 23px;
    left: 41.5%;
  }
  .closeClock{
    left: 35% !important;
  }
  .burgerImg{
    top: 8px;
  }
  .langContainer{
    right: 45px;
  }
  .parent{
    left: 37% !important;
  }
}
@media screen and (min-width: 601px) and (max-width: 680px){
  .burgerContainer{
    height: 250px;
    width: 185px;
  }
  .navBottom{
    height: 80px; 
  }
  .burgerClose{
    left: 150px;
  }
  .navItemsContainer a p{
    margin-bottom: 9px !important;
  }
  .navItemsContainer a{
    font-size: 14px;
  }
  .fblogoNav{
    transform: scale(1);
    bottom: 23px;
    left: 41.5%;
  }
  .closeClock{
    left: 38% !important;
  }
  .burgerImg{
    top: 8px;
  }
  .langContainer{
    right: 45px;
  }
  .parent{
    left: 37% !important;
  }
}
@media screen and (min-width: 681px) and (max-width: 719px){
  .burgerContainer{
    height: 250px;
    width: 185px;
  }
  .navBottom{
    height: 80px; 
  }
  .burgerClose{
    left: 150px;
  }
  .navItemsContainer a p{
    margin-bottom: 9px !important;
  }
  .navItemsContainer a{
    font-size: 14px;
  }
  .fblogoNav{
    transform: scale(1);
    bottom: 23px;
    left: 41.5%;
  }
  .closeClock{
    left: 39% !important;
  }
  .burgerImg{
    top: 8px;
  }
  .langContainer{
    right: 55px;
  }
  .parent{
    left: 39% !important;
  }
}