html{
    scroll-behavior: smooth;
}
.bottomContainer{
    width: 100%;
    height: 860px;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: #F3F3F3;
    position: absolute;
    top: 2850px;
}
.copyright{
    position: absolute;
    top: 3760px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'LemonMilklight';
    text-align: center;
    font-size: 12px;
    width: 100%;
}
@media screen and (max-width: 319px){
    .bottomContainer{
        top: 3400px;
    }
    .copyright{
        top: 4309px;
        font-size: 10px;
    }
}
@media screen and (min-width: 320px) and (max-width: 339px){
    .bottomContainer{
        top: 3400px;
    }
    .copyright{
        top: 4309px;
        font-size: 10px;
    }
}
@media screen and (min-width: 340px) and (max-width: 359px){
    .bottomContainer{
        top: 3130px;
    }
    .copyright{
        top: 4040px;
        font-size: 11px;
    }
}
@media screen and (min-width: 360px) and (max-width: 399px){
    .bottomContainer{
        top: 3130px;
    }
    .copyright{
        top: 4040px;
        font-size: 11px;
    }
}
@media screen and (min-width: 400px) and (max-width: 470px){
    .bottomContainer{
        top: 3130px;
    }
    .copyright{
        top: 4040px;
    }
}
@media screen and (min-width: 471px) and (max-width: 540px){
    .bottomContainer{
        top: 3150px;
    }
    .copyright{
        top: 4060px;
    }
}
@media screen and (min-width: 541px) and (max-width: 600px){
    .bottomContainer{
        top: 3000px;
    }
    .copyright{
        top: 3910px;
    }
}
@media screen and (min-width: 601px) and (max-width: 680px){
    .bottomContainer{
        top: 3000px;
    }
    .copyright{
        top: 3910px;
    }
}
@media screen and (min-width: 681px) and (max-width: 719px){
    .bottomContainer{
        top: 3000px;
    }
    .copyright{
        top: 3910px;
    }
}
@media screen and (min-width: 720px) and (max-width: 799px){
    .bottomContainer{
        top: 3000px;
    }
    .copyright{
        top: 3910px;
    }
}
@media screen and (min-width: 800px) and (max-width: 1023px){
    .bottomContainer{
        top: 2050px;
    }
    .copyright{
        top: 2960px;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1279px){
    .bottomContainer{
        top: 2220px;
        height: 480px;
    }
    .copyright{
        top: 2750px;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1365px){
    .bottomContainer{
        top: 2240px;
        height: 480px;
    }
    .copyright{
        top: 2770px;
    }
}
@media screen and (min-width: 1366px) and (max-width: 1599px){
    .bottomContainer{
        top: 2240px;
        height: 480px;
    }
    .copyright{
        top: 2770px;
    }
}
@media screen and (min-width: 1600px) and (max-width: 1919px){
    .bottomContainer{
        top: 2074px;
        height: 480px;
    }
    .copyright{
        top: 2606px;
    }
}
@media screen and (min-width: 1920px) and (max-width: 2047px){
    .bottomContainer{
        top: 2000px;
        height: 480px;
    }
    .copyright{
        top: 2530px;
    }
}
@media screen and (min-width: 2048px) and (max-width: 2559px){
    .bottomContainer{
        top: 2000px;
        height: 480px;
    }
    .copyright{
        top: 2530px;
    }
}
@media screen and (min-width: 2560px) and (max-width: 3655px){
    .bottomContainer{
        top: 2000px;
        height: 480px;
    }
    .copyright{
        top: 2530px;
    }
}
@media screen and (min-width: 3656px) and (max-width: 3839px){
    .bottomContainer{
        top: 2000px;
        height: 480px;
    }
    .copyright{
        top: 2530px;
    }
}
@media screen and (min-width: 3840px) and (max-width: 4095px){
    .bottomContainer{
        top: 2000px;
        height: 480px;
    }
    .copyright{
        top: 2530px;
    }
}
@media screen and (min-width: 4096px){
    .bottomContainer{
        top: 2300px;
        height: 480px;
    }
    .copyright{
        top: 2830px;
        font-size: 16px;
    }
}