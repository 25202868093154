.clanokContainer{
    width: 90%;
    height: 198px;
    position: relative;
    top: 275px;
    background-color: #F3F3F3;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 38px 3px rgba(0,0,0,0.39); 
    box-shadow: 0px 0px 38px 3px rgba(0,0,0,0.39);
    z-index: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-width: 730px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    cursor: pointer;
    margin: auto;
}
.clanokContainer::-webkit-scrollbar {
    display: none;
}
.clanokContainer[data-isOpen="true"]{
    height: 550px; 
    top: 70px;
    overflow: scroll;
}
.clanokLogo{
    position: absolute;
    left: -147px;
    top: -145px;
    transform: scale(0.1);
}
.clanokChild{
    width: 100%;
    height: 750px;
    border-radius: 15px;
    text-align: center;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 710;
}
#nadpis{
    font-size: 18px;
    position: absolute;
    width: 90%;
    top: 0px;
    font-family: 'LemonMilklight';
    margin-left: 28px;
}
.clanok{
    text-align: left;
    position: absolute;
    top: 35px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'LemonMilklight';
    font-size: 13px;
    color: #848484;
}
.citajViacBut{
    position: absolute;
    bottom: 10px;
}
.vsuvka{
    text-align: right;
    margin-right: 10px;
    margin-top: 0px;
    margin-bottom: 50px;
}
.vsuvka[data-isOpen="true"]{
    display: none;
}
.linkClanokDomov{
    color: black;
    text-decoration: none !important;
}
@media screen and (min-width: 240px) and (max-width: 342px){
    #nadpis{ 
        font-size: 13px;
    }
    .clanokContainer{
        height: 249px;
    }
}
@media screen and (min-width: 343px) and (max-width: 374px){
    #nadpis{ 
        font-size: 17px;
    }
    .clanokContainer{
        height: 235px;
    }
}
@media screen and (min-width: 375px) and (max-width: 446px){
    #nadpis{ 
        font-size: 17px;
    }
    .clanokContainer{
        height: 215px;
    }
}
@media screen and (min-width: 447px) and (max-width: 502px){
    .clanokContainer{
        height: 201px;
    }
    .clanokChild{
        height: 706px;
    }
}
@media screen and (min-width: 503px) and (max-width: 631px){
    .clanokContainer{
        height: 186px;
    }
    .clanokChild{
        height: 626px;
    }
}
@media screen and (min-width: 632px) and (max-width: 812px){
    .clanokContainer{
        height: 170px;
    }
    .clanokChild{
        height: 520px;
    }
    .clanokContainer[data-isOpen="true"]{
        height: 520px; 
    }
}
@media screen and (min-width: 700px) and (max-width: 812px){
    .clanokChild{
        height: 490px;
    }
    .clanokContainer[data-isOpen="true"]{
        height: 490px; 
    }
}
@media screen and (min-width: 813px){
    .clanokContainer{
        height: 170px;
    }
    .clanokChild{
        height: 440px;
    }
    .clanokContainer[data-isOpen="true"]{
        height: 440px; 
    }
}
@media screen and (min-width: 1024px) and (max-width: 1279px){
    .clanokContainer[data-isOpen="true"]{
        position: fixed;
        left: 14.7vw;
        top: 75px;
    }
    .clanokContainer{
        width: 70%;
        top: 440px;
        max-width: 913px;
    }
    #nadpis{
        font-size: 20px;
    }
    .clanok{
        font-size: 18px;
    }
    .vsuvka{
        margin-top: -35px;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1365px){
    .clanokContainer[data-isOpen="true"]{
        position: fixed;
        left: 14.7vw;
        top: 75px;
    }
    .clanokContainer{
        width: 90%;
        top: 470px;
        max-width: 913px;
    }
    #nadpis{
        font-size: 20px;
    }
    .clanok{
        font-size: 18px;
    }
    .vsuvka{
        margin-top: -21px;
    }
}
@media screen and (min-width: 1366px) and (max-width: 1599px){
    .clanokContainer[data-isOpen="true"]{
        position: fixed;
        left: 18vw;
        top: 75px;
    }
    .clanokContainer{
        width: 90%;
        top: 470px;
        max-width: 913px;
    }
    #nadpis{
        font-size: 20px;
    }
    .clanok{
        font-size: 18px;
    }
    .vsuvka{
        margin-top: -21px;
    }
}
@media screen and (min-width: 1600px) and (max-width: 1919px){
    .clanokContainer[data-isOpen="true"]{
        position: fixed;
        left: 21.7vw;
        top: 255px;
    }
    .clanokContainer{
        width: 90%;
        top: 535px;
        max-width: 913px;
    }
    #nadpis{
        font-size: 20px;
    }
    .clanok{
        font-size: 18px;
    }
    .vsuvka{
        margin-top: -21px;
    }
}
@media screen and (min-width: 1920px) and (max-width: 2047px){
    .clanokContainer[data-isOpen="true"]{
        position: fixed;
        left: 25.7vw;
        top: 255px;
    }
    .clanokContainer{
        width: 90%;
        top: 535px;
        max-width: 913px;
    }
    #nadpis{
        font-size: 20px;
    }
    .clanok{
        font-size: 18px;
    }
    .vsuvka{
        margin-top: -21px;
    }
}
@media screen and (min-width: 2048px) and (max-width: 2559px){
    .clanokContainer[data-isOpen="true"]{
        position: fixed;
        left: 27.7vw;
        top: 255px;
        height: 615px;
    }
    .clanokContainer{
        width: 90%;
        top: 535px;
        max-width: 913px;
    }
    #nadpis{
        font-size: 20px;
    }
    .clanok{
        font-size: 18px;
    }
    .vsuvka{
        margin-top: -21px;
    }
}
@media screen and (min-width: 2560px) and (max-width: 3655px){
    .clanokContainer[data-isOpen="true"]{
        position: fixed;
        left: 32vw;
        top: 255px;
        height: 615px;
    }
    .clanokContainer{
        width: 90%;
        top: 535px;
        max-width: 913px;
    }
    #nadpis{
        font-size: 20px;
    }
    .clanok{
        font-size: 18px;
    }
    .vsuvka{
        margin-top: -21px;
    }
}
@media screen and (min-width: 3656px) and (max-width: 3839px){
    .clanokContainer[data-isOpen="true"]{
        position: fixed;
        left: 37.7vw;
        top: 255px;
        height: 615px;
    }
    .clanokContainer{
        width: 90%;
        top: 535px;
        max-width: 913px;
    }
    #nadpis{
        font-size: 20px;
    }
    .clanok{
        font-size: 18px;
    }
    .vsuvka{
        margin-top: -21px;
    }
}
@media screen and (min-width: 3840px) and (max-width: 4095px){
    .clanokContainer[data-isOpen="true"]{
        position: fixed;
        left: 27.7vw;
        top: 255px;
        height: 615px;
    }
    .clanokContainer{
        width: 90%;
        top: 535px;
        max-width: 913px;
    }
    #nadpis{
        font-size: 20px;
    }
    .clanok{
        font-size: 18px;
    }
    .vsuvka{
        margin-top: -21px;
    }
}
@media screen and (min-width: 4096px){
    .clanokContainer[data-isOpen="true"]{
        position: fixed;
        left: 38.7vw;
        top: 255px;
        height: 615px;
    }
    .clanokContainer{
        width: 90%;
        top: 535px;
        max-width: 913px;
    }
    #nadpis{
        font-size: 20px;
    }
    .clanok{
        font-size: 18px;
    }
    .vsuvka{
        margin-top: -21px;
    }
}