.cennikContainer{
    width: 90%;
    left: 5%;
    height: 400px;
    position: absolute;
    top: 520px;
}
h2{
    text-align: center;
    width: 100%;
    font-family: 'LemonMilklight';
    font-size: 18px;
}
h3{
    font-family: 'LemonMilk';
}
.cennikItem{
    background-color:#F3F3F3;
    width: 90%;
    /*height: 450px;*/
    display: inline-block;
    position: relative;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 21px 4px #CFCFCF; 
    box-shadow: 0px 0px 21px 4px #CFCFCF;
    top: 0px;
    margin-top: 30px;
    overflow: hidden;
}
.prvy{ 
    left: 5%;
}
.druhy{
    left: 5%;
}
.treti{
    left: 5%;
}
.stvrty{
    left: 5%;
}
.cennikItem h3{
    font-size: 20px;
    text-align: center;
    position:relative;
    letter-spacing: 2px;
    color: #4BEC69;
}
.cennikNadpis{
    text-align: center;
    top: 0px;
    position: relative;
    font-family: 'LemonMilklight';
    color: #848484;
    font-size: 16px;
    max-width: 760px;
    margin: auto;
}
.cennikItemLogo{
    width: 100%;
    transform: scale(1);
    position: relative;
    left: 0%;
    top: -18px;
}
.cennikItemTextPrvy{
    font-size: 15px;
    font-family: 'LemonMilklight';
    text-align: center;
    position: relative;
    top: -32px;
    color: rgb(255, 255, 255);
    width: 90%;
    left: 5%;
}
.cenaBox{
    width: 90%;
    height: 45px;
    background-color: #559AD6;
    position: relative;
    left: 5%;
    border-radius: 30px;
    margin-top: 13px;
    top: -20px;
}
.cenaBox p{
    display: inline-block;
    position: absolute;
    margin-top: 13px;
    font-size: 16px;
    font-family: 'LemonMilklight'
}
.single{
    top: 30px !important;
}
.itemText{
    left: 5%;
    color: #4BEC69;
}
.itemCena{
    left: 80%;
    color: #4BEC69;
}
.itemCenaDoma{
    left: 55%;
    color: #4BEC69;
}
.cennikitemTextDruhy{
    font-family: 'LemonMilklight';
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);
    position: relative;
    top: -10px;
    width: 90%;
    left: 5%;
}
.druhySingle{
    top: 60px !important;
}
.prvyModry{
    width: 100%;
    height: 100%;
    background-color: #3B82C1;
    position: absolute;
    opacity: 0.85;
}
.backgroundItem{
    background-image: url('../img/cenikItemBack.png');
    background-size: cover;
    background-position: bottom left -10px;
    width: 110%;
    height: 110%;
    position: absolute;
}
@media screen and (max-width: 319px){
    .itemText{
        font-size: 14px !important;
    }
    .itemCena{
        font-size: 14px !important;
    }
    .itemCenaDoma{
        font-size: 14px !important;
    }
    .cennikItem h3{
        font-size: 18px;
    }
    .cennikItem{
        width: 90%;
        height: 460px;
    }
    .cennikContainer{
        top: 600px;
    }
    .itemCenaDoma{
        left: 55%;
    }
    .cennikItemLogo{
        width: 60%;
        left: 20%;
    }
    .single{
        top: 0px !important;
    }
    .backgroundItem{
        background-position: bottom 10px left -17px;
    }
    .druhySingle{
        top: 35px !important;
    }
}
@media screen and (min-width: 320px) and (max-width: 339px){
    .itemText{
        font-size: 14px !important;
    }
    .itemCena{
        font-size: 14px !important;
    }
    .itemCenaDoma{
        font-size: 14px !important;
    }
    .cennikItem h3{
        font-size: 18px;
    }
    .cennikItem{
        width: 90%;
        height: 460px;
    }
    .cennikContainer{
        top: 600px;
    }
    .itemCenaDoma{
        left: 55%;
    }
    .cennikItemLogo{
        width: 60%;
        left: 20%;
    }
    .single{
        top: 0px !important;
    }
    .backgroundItem{
        background-position: bottom 10px left -17px;
    }
    .druhySingle{
        top: 35px !important;
    }
}
@media screen and (min-width: 340px) and (max-width: 359px){
    .itemText{
        font-size: 14px !important;
    }
    .itemCena{
        font-size: 14px !important;
    }
    .itemCenaDoma{
        font-size: 14px !important;
    }
    .cennikItem h3{
        font-size: 19px;
    }
    .cennikItem{
        width: 90%;
        height: 440px;
    }
    .cennikContainer{
        top: 550px;
    }
    .itemCenaDoma{
        left: 55%;
    }
    .cennikItemLogo{
        width: 60%;
        left: 20%;
    }
    .single{
        top: 0px !important;
    }
    .backgroundItem{
        background-position: bottom 10px left -17px;
    }
    .druhySingle{
        top: 35px !important;
    }
}
@media screen and (min-width: 360px) and (max-width: 450px){
    .itemText{
        font-size: 15px !important;
    }
    .itemCena{
        font-size: 15px !important;
    }
    .itemCenaDoma{
        font-size: 15px !important;
    }
    .cennikItem{
        width: 90%;
        height: 440px;
    }
    .cennikContainer{
        top: 550px;
    }
    .itemCenaDoma{
        left: 55%;
    }
    .cennikItemLogo{
        width: 60%;
        left: 20%;
    }
    .single{
        top: 0px !important;
    }
    .backgroundItem{
        background-position: bottom left -17px;
    }
    .druhySingle{
        top: 35px !important;
    }
}
@media screen and (min-width: 450px) and (max-width: 499px){
    .cennikItem{
        width: 90%;
        height: 440px;
    }
    .cennikContainer{
        top: 550px;
    }
    .itemCenaDoma{
        left: 60%;
    }
    .cennikItemLogo{
        width: 60%;
        left: 20%;
    }
    .single{
        top: 0px !important;
    }
    .backgroundItem{
        background-position: bottom left -17px;
    }
    .druhySingle{
        top: 35px !important;
    }
}
@media screen and (min-width: 500px) and (max-width: 549px){
    .cennikItem{
        width: 90%;
        height: 440px;
    }
    .cennikContainer{
        top: 550px;
    }
    .itemCenaDoma{
        left: 65%;
    }
    .cennikItemLogo{
        width: 60%;
        left: 20%;
    }
    .single{
        top: 0px !important;
    }
    .backgroundItem{
        background-position: bottom left -17px;
    }
    .druhySingle{
        top: 35px !important;
    }
}
@media screen and (min-width: 550px) and (max-width: 599px){
    .cennikItem{
        width: 90%;
        height: 440px;
    }
    .cennikContainer{
        top: 550px;
    }
    .itemCenaDoma{
        left: 70%;
    }
    .cennikItemLogo{
        width: 60%;
        left: 20%;
    }
    .single{
        top: 0px !important;
    }
    .backgroundItem{
        background-position: bottom left -17px;
    }
    .druhySingle{
        top: 35px !important;
    }
}
@media screen and (min-width: 600px) and (max-width: 649px){
    .cennikItem{
        width: 90%;
        height: 440px;
    }
    .cennikContainer{
        top: 550px;
    }
    .itemCenaDoma{
        left: 70%;
    }
    .cennikItemLogo{
        width: 60%;
        left: 20%;
    }
    .single{
        top: 0px !important;
    }
    .backgroundItem{
        background-position: bottom left -17px;
    }
    .druhySingle{
        top: 35px !important;
    }
}
@media screen and (min-width: 650px) and (max-width: 799px){
    .cennikItem{
        width: 90%;
        height: 440px;
    }
    .cennikContainer{
        top: 550px;
    }
    .itemCenaDoma{
        left: 70%;
    }
    .cennikItemLogo{
        width: 60%;
        left: 20%;
    }
    .single{
        top: 0px !important;
    }
    .backgroundItem{
        background-position: bottom left -17px;
    }
    .druhySingle{
        top: 35px !important;
    }
}
@media screen and (min-width: 800px) and (max-width: 1023px){
    .cennikItem{
        width: 43%;
        height: 440px;
    }
    .druhy{
        left: 9%;
    }
    .stvrty{
        left: 9%;
    }
    .cennikContainer{
        top: 480px;
    }
    .itemCenaDoma{
        left: 70%;
    }
    .cennikItemLogo{
        width: 60%;
        left: 20%;
    }
    .single{
        top: 0px !important;
    }
    .backgroundItem{
        background-position: bottom left -17px;
    }
    .druhySingle{
        top: 35px !important;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1279px){
    .cennikItem{
        width: 43%;
        height: 440px;
    }
    .druhy{
        left: 9%;
    }
    .stvrty{
        left: 9%;
    }
    .cennikContainer{
        top: 730px;
    }
    .itemCenaDoma{
        left: 70%;
    }
    .cennikItemLogo{
        width: 60%;
        left: 20%;
    }
    .single{
        top: 0px !important;
    }
    .backgroundItem{
        background-position: bottom left -17px;
    }
    .druhySingle{
        top: 35px !important;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1365px){
    .cennikItem{
        width: 43%;
        height: 440px;
    }
    .druhy{
        left: 9%;
    }
    .stvrty{
        left: 9%;
    }
    .cennikContainer{
        top: 730px;
    }
    .itemCenaDoma{
        left: 70%;
    }
    .cennikItemLogo{
        width: 60%;
        left: 20%;
    }
    .single{
        top: 0px !important;
    }
    .backgroundItem{
        background-position: bottom left -17px;
    }
    .druhySingle{
        top: 35px !important;
    }
}
@media screen and (min-width: 1366px) and (max-width: 1599px){
    .cennikItem{
        width: 43%;
        height: 440px;
    }
    .druhy{
        left: 9%;
    }
    .stvrty{
        left: 9%;
    }
    .cennikContainer{
        top: 730px;
    }
    .itemCenaDoma{
        left: 70%;
    }
    .cennikItemLogo{
        width: 60%;
        left: 20%;
    }
    .single{
        top: 0px !important;
    }
    .backgroundItem{
        background-position: bottom left -17px;
    }
    .druhySingle{
        top: 35px !important;
    }
}
@media screen and (min-width: 1600px) and (max-width: 1919px){
    .cennikItem{
        width: 22%;
        height: 475px;
        position: absolute;
        top: 230px;
        overflow: hidden;
    }
    .prvy{
        left: 1.5%;
    }
    .druhy{
        left: 26.5%;
    }
    .treti{
        left: 51.5%;
    }
    .stvrty{
        left: 76.5%;
    }
    .cennikContainer{
        top: 777px;
        left: 5%;
        height: 400px;
        width: 90%;
    }
    .itemCenaDoma{
        left: 53%;
    }
    h2{
        font-size: 30px;
    }
    .cennikNadpis{
        font-size: 21px;
        top: 0px;
        max-width: 760px;
    }
    .prvy:hover{
        top: 220px;
    }
    .druhy:hover{
        top: 220px;
    }
    .treti:hover{
        top: 220px;
    }
    .stvrty:hover{
        top: 220px;
    }
}
@media screen and (min-width: 1920px) and (max-width: 2047px){
    .cennikItem{
        width: 22%;
        height: 475px;
        position: absolute;
        top: 230px;
        overflow: hidden;
    }
    .prvy{
        left: 1.5%;
    }
    .druhy{
        left: 26.5%;
    }
    .treti{
        left: 51.5%;
    }
    .stvrty{
        left: 76.5%;
    }
    .cennikContainer{
        top: 777px;
        left: 5%;
        height: 400px;
        width: 90%;
    }
    .itemCenaDoma{
        left: 53%;
    }
    h2{
        font-size: 30px;
    }
    .cennikNadpis{
        font-size: 21px;
        top: 0px;
        max-width: 760px;
    }
    .prvy:hover{
        top: 220px;
    }
    .druhy:hover{
        top: 220px;
    }
    .treti:hover{
        top: 220px;
    }
    .stvrty:hover{
        top: 220px;
    }
    .single{
        top: 40px !important;
    }
    .druhySingle{
        top: 65px !important;
    }
}
@media screen and (min-width: 1024px){
    .cennikItem{
        transition: all .2s ease-in-out;
    }
}
@media screen and (min-width: 2048px) and (max-width: 2559px){
    .cennikItem{
        width: 24%;
        height: 500px;
        position: absolute;
        top: 230px;
        overflow: hidden;
    }
    .prvy{
        left: 1.5%;
    }
    .druhy{
        left: 26.5%;
    }
    .treti{
        left: 51.5%;
    }
    .stvrty{
        left: 76.5%;
    }
    .cennikContainer{
        top: 777px;
        left: 15.1%;
        height: 400px;
        width: 70%;
    }
    .itemCenaDoma{
        left: 53%;
    }
    h2{
        font-size: 30px;
    }
    .cennikNadpis{
        font-size: 21px;
        top: 0px;
        max-width: 760px;
    }
    .prvy:hover{
        top: 220px;
    }
    .druhy:hover{
        top: 220px;
    }
    .treti:hover{
        top: 220px;
    }
    .stvrty:hover{
        top: 220px;
    }
    .single{
        top: 40px !important;
    }
    .druhySingle{
        top: 65px !important;
    }
}
@media screen and (min-width: 2560px) and (max-width: 3655px){
    .cennikItem{
        width: 22%;
        height: 475px;
        position: absolute;
        top: 230px;
        overflow: hidden;
    }
    .prvy{
        left: 1.5%;
    }
    .druhy{
        left: 26.5%;
    }
    .treti{
        left: 51.5%;
    }
    .stvrty{
        left: 76.5%;
    }
    .cennikContainer{
        top: 777px;
        left: 15.1%;
        height: 400px;
        width: 70%;
    }
    .itemCenaDoma{
        left: 53%;
    }
    h2{
        font-size: 30px;
    }
    .cennikNadpis{
        font-size: 21px;
        top: 0px;
        max-width: 760px;
    }
    .prvy:hover{
        top: 220px;
    }
    .druhy:hover{
        top: 220px;
    }
    .treti:hover{
        top: 220px;
    }
    .stvrty:hover{
        top: 220px;
    }
    .single{
        top: 40px !important;
    }
    .druhySingle{
        top: 65px !important;
    }
}
@media screen and (min-width: 3656px) and (max-width: 3839px){
    .cennikItem{
        width: 22%;
        height: 540px;
        position: absolute;
        top: 230px;
        overflow: hidden;
    }
    .prvy{
        left: 1.5%;
    }
    .druhy{
        left: 26.5%;
    }
    .treti{
        left: 51.5%;
    }
    .stvrty{
        left: 76.5%;
    }
    .cennikContainer{
        top: 777px;
        left: 15.1%;
        height: 400px;
        width: 70%;
    }
    .itemCenaDoma{
        left: 53%;
    }
    h2{
        font-size: 30px;
    }
    .cennikNadpis{
        font-size: 21px;
        top: 0px;
        max-width: 760px;
    }
    .prvy:hover{
        top: 220px;
    }
    .druhy:hover{
        top: 220px;
    }
    .treti:hover{
        top: 220px;
    }
    .stvrty:hover{
        top: 220px;
    }
    .single{
        top: 40px !important;
    }
    .druhySingle{
        top: 65px !important;
    }
    .backgroundItem{
        background-position:bottom left -20px;
    }
}
@media screen and (min-width: 3840px) and (max-width: 4095px){
    .cennikItem{
        width: 22%;
        height: 475px;
        position: absolute;
        top: 230px;
        overflow: hidden;
    }
    .prvy{
        left: 1.5%;
    }
    .druhy{
        left: 26.5%;
    }
    .treti{
        left: 51.5%;
    }
    .stvrty{
        left: 76.5%;
    }
    .cennikContainer{
        top: 777px;
        left: 15.1%;
        height: 400px;
        width: 70%;
    }
    .itemCenaDoma{
        left: 53%;
    }
    h2{
        font-size: 30px;
    }
    .cennikNadpis{
        font-size: 21px;
        top: 0px;
        max-width: 760px;
    }
    .prvy:hover{
        top: 220px;
    }
    .druhy:hover{
        top: 220px;
    }
    .treti:hover{
        top: 220px;
    }
    .stvrty:hover{
        top: 220px;
    }
    .single{
        top: 40px !important;
    }
    .druhySingle{
        top: 65px !important;
    }
}
@media screen and (min-width: 4096px){
    .cennikItem{
        width: 22%;
        height: 550px;
        position: absolute;
        top: 230px;
        overflow: hidden;
    }
    .prvy{
        left: 1.5%;
    }
    .druhy{
        left: 26.5%;
    }
    .treti{
        left: 51.5%;
    }
    .stvrty{
        left: 76.5%;
    }
    .cennikContainer{
        top: 950px;
        left: 20%;
        height: 400px;
        width: 60%;
    }
    .itemCenaDoma{
        left: 53%;
    }
    h2{
        font-size: 30px;
    }
    .cennikNadpis{
        font-size: 21px;
        top: 0px;
        max-width: 760px;
    }
    .prvy:hover{
        top: 220px;
    }
    .druhy:hover{
        top: 220px;
    }
    .treti:hover{
        top: 220px;
    }
    .stvrty:hover{
        top: 220px;
    }
    .single{
        top: 40px !important;
    }
    .druhySingle{
        top: 65px !important;
    }
}