.desktopNavContainer{
    position: fixed;
    z-index: 1000000;
    width: 100%;
    background-color: transparent;
    transition: all .2s ease-in-out;
}
.desktopNavBlur{
    position: fixed;
    z-index: 100000;
    background-color: white;
    opacity: 0.2;
    width: 100%;
    height: 58px;
}
.small{
    background-color: white;
    color: black;
    position: fixed;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.small a p{
    color: black !important;
    background: linear-gradient(#000000, #000000) bottom / 0 .1em no-repeat !important;
    transition: 0.5s background-size !important;
    background-position: left 0px top 20px !important;
    font-family: 'LemonMilk';
    letter-spacing:2px;
}
.small a p:hover{
    background-size: 100% .15em !important;
}
.desktopNavContainer a p{
    display: inline-block;
    margin-left: 20px;
    color: white;
    text-decoration: none;
    font-family: 'LemonMilk';
    letter-spacing:2px;
}
.desktopNavContainer a p{
    background: linear-gradient(#ffffff, #ffffff) bottom / 0 .1em no-repeat;
	transition: 0.5s background-size;
    background-position: left 0px top 20px;
    padding-bottom: 3px;
}
.desktopNavContainer a p:hover{
    background-size: 100% .15em;
}
.langContainer{
    top: 12px !important;
    z-index: 199999966;
}
.fblogohore{
    top: 12px;
    position: absolute;
    left: 506px;
}
@media screen and (max-width: 319px){

}
@media screen and (min-width: 320px) and (max-width: 379px){
    .parent{
        top: 13px;
    }
}
@media screen and (min-width: 380px) and (max-width: 411px){
    .parent{
        top: 13px;
    }
}
@media screen and (min-width: 412px) and (max-width: 450px){
    .parent{
        top: 13px;
    }
}
@media screen and (min-width: 451px) and (max-width: 490px){
    .parent{
        top: 13px;
    }
}
@media screen and (min-width: 491px) and (max-width: 550px){
    .parent{
        top: 13px;
    }
}
@media screen and (min-width: 551px) and (max-width: 600px){
    .parent{
        top: 13px;
    }
}
@media screen and (min-width: 601px) and (max-width: 719px){
    .parent{
        top: 13px;
    }
}
@media screen and (min-width: 720px) and (max-width: 799px){
    .parent{
        left: 60%;
    }
}
@media screen and (min-width: 800px) and (max-width: 899px){
    .parent{
        left: 65%;
    }
}
@media screen and (min-width: 900px) and (max-width: 1023px){
    .parent{
        left: 68%;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1279px){
    .parent{
        left: 72%;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1365px){
    .parent{
        left: 78%;
    }
}
@media screen and (min-width: 1366px) and (max-width: 1599px){
    .parent{
        left: 79%;
    }
}
@media screen and (min-width: 1600px) and (max-width: 1919px){
    .parent{
        left: 82%;
    }
}
@media screen and (min-width: 1920px) and (max-width: 2047px){
    
}
@media screen and (min-width: 2048px) and (max-width: 2559px){
    .desktopNavContainer a p{
        font-size: 24px;
        background-position: left 0px top 33px;
    }
    .small a p{
        background-position: left 0px top 33px !important;
    }
    .desktopNavBlur{
        height: 85px;
    }
    .fblogohore{
        top:25px;
        left: 663px;
    }
    .langContainer{
        top: 26px !important;
    }
}
@media screen and (min-width: 2560px) and (max-width: 3655px){
    .desktopNavContainer a p{
        font-size: 24px;
        background-position: left 0px top 33px;
    }
    .small a p{
        background-position: left 0px top 33px !important;
    }
    .desktopNavBlur{
        height: 85px;
    }
    .fblogohore{
        top:25px;
        left: 670px;
    }
    .langContainer{
        top: 26px !important;
    }
}
@media screen and (min-width: 3656px) and (max-width: 3839px){
    .desktopNavContainer a p{
        font-size: 24px;
        background-position: left 0px top 33px;
    }
    .small a p{
        background-position: left 0px top 33px !important;
    }
    .desktopNavBlur{
        height: 85px;
    }
    .fblogohore{
        top:25px;
        left: 670px;
    }
    .langContainer{
        top: 26px !important;
    }
}
@media screen and (min-width: 3840px) and (max-width: 4095px){
    .desktopNavContainer a p{
        font-size: 30px;
    }
    .desktopNavBlur{
        height: 100px;
    }
}
@media screen and (min-width: 4096px){
    .desktopNavContainer a p{
        font-size: 30px;
    }
    .desktopNavBlur{
        height: 100px;
    }
}