.langContainer{
    width: 80px;
    height: 33px;
    position: fixed;
    /*left: 65%;*/
    top: 25px;
    right: 50px;
    z-index: 1000000000;
}
.flagImg{
    position: absolute;
    left: 60px;
    cursor: pointer;
    z-index: 700;
}
.parent2 {
    z-index: 699;
    width: 39px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 21px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 5px 0px 0px 5px !important;
    background-color:#0052B4;
    color: white;
  }
  .parent2[data-isOpenFlag="true"] {
    width: 39px;
    height: 115px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .parent2[data-isOpenFlag="false"] .openContainer{
    display: none;
  }
.DE{
    background-color:black;
}
.EN{
    background-color:white;
}
.SK{
    background-color:#0052B4;
}
.SK p{
  color: white;
  font-family: 'LemonMilk';
}
.DE p{
  color: white;
  font-family: 'LemonMilk';
}
.EN p{
  color: black;
  font-family: 'LemonMilk';
}
.child2 {
    width: 33px;
    height: 37px;
    border-radius: 5px 5px 0px 5px;
    color: white;
    text-align: center;
    position: absolute;
    top: -10px;
    left: 4px;
    cursor: pointer;
  }

.flagText p{
    margin-top: 5px;
    margin-bottom: 5px;
}
@media screen and (max-width: 360px){
    .langContainer{
      right: 38px;
    }
}