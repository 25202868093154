.mapContainer{
    width: 90%;
    height: 300px;
    position: absolute;
    left: 5%;
    top: 400px;
}
.mapContainer h3{
    text-align: center;
    margin-bottom: 10px;
}
#BD9BF948-AA57-4360-8075-2F128F465245{
    display: none !important;
}
#root > div > div.bottomContainer > div.mapContainer > div > div > div:nth-child(1) > div > div > div:nth-child(1) > button{
    display: none !important;
}
.gm-style-mtc{
    display: none !important;
}
.name{
    font-family: 'LemonMilklight';
    text-align: center;
    margin-top: 5px;
    margin-bottom:10px;
}
.bottomContact{
    text-align: center;
    font-family: 'LemonMilklight';
    font-size: 16px;
    margin-bottom: 8px;
    margin-top: 8px;
    background-color: white;
    border-radius: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.fblogodole{
    position: absolute;
    left: 46%;
    margin-top: 5px;
}
@media screen and (max-width: 319px){
    .bottomContact{
        font-size: 14px;
    }
}
@media screen and (min-width: 320px) and (max-width: 339px){
    .bottomContact{
        font-size: 14px;
    }
}
@media screen and (min-width: 340px) and (max-width: 359px){
    
}
@media screen and (min-width: 400px) and (max-width: 470px){
    
}
@media screen and (min-width: 471px) and (max-width: 540px){
    
}
@media screen and (min-width: 541px) and (max-width: 600px){
    
}
@media screen and (min-width: 601px) and (max-width: 680px){
    
}
@media screen and (min-width: 681px) and (max-width: 719px){
    
}
@media screen and (min-width: 720px) and (max-width: 799px){
    
}
@media screen and (min-width: 800px) and (max-width: 1023px){
    
}
@media screen and (min-width: 1024px) and (max-width: 1279px){
    .mapContainer{
        width: 45%;
        left: 53%;
        top: 0px;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1365px){
    .mapContainer{
        width: 45%;
        left: 53%;
        top: 0px;
    }
    .fblogodole{
        left: 48%;
    }
}
@media screen and (min-width: 1366px) and (max-width: 1599px){
    .mapContainer{
        width: 45%;
        left: 53%;
        top: 0px;
    }
    .fblogodole{
        left: 48%;
    }
}
@media screen and (min-width: 1600px) and (max-width: 1919px){
    .mapContainer{
        width: 45%;
        left: 53%;
        top: 0px;
    }
    .fblogodole{
        left: 48%;
    }
}
@media screen and (min-width: 1920px) and (max-width: 2047px){
    .mapContainer{
        width: 45%;
        left: 53%;
        top: 0px;
    }
}
@media screen and (min-width: 2048px) and (max-width: 2559px){
    .mapContainer{
        width: 35%;
        left: 53%;
        top: 0px;
    }
}
@media screen and (min-width: 2560px) and (max-width: 3655px){
    .mapContainer{
        width: 35%;
        left: 53%;
        top: 0px;
    }
}
@media screen and (min-width: 3656px) and (max-width: 3839px){
    .mapContainer{
        width: 35%;
        left: 53%;
        top: 0px;
    }
}
@media screen and (min-width: 3840px) and (max-width: 4095px){
    .mapContainer{
        width: 35%;
        left: 53%;
        top: 0px;
    }
}