.sipkaObdlznik{
    width: 19px;
    height: 19px;
    background-color: #F3F3F3;
    position: absolute; 
    left: -4px;
    top: 7px;
    border-radius: 2px;
}
.sipka{
    position: absolute;
    left: 6px;
    top: 9px;
}
.otvoreneText{
    color: white;
    position: absolute;
    margin: 0;
    left: 26px;
    top: 0px;
    font-size: 18px;
    font-family: 'LemonMilkLight';
}
.openClock{
    background-color: #AECD3B;
}
.closeClock{
    background-color: #D80027;
}
.parent {
    z-index: 199999966;
    width: 35%;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 25%;
    top: 25px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 5px !important;
    min-width: 130px;
    max-width: 150px;
  }
  .parent[data-openLayout="true"] {
    width: 40%;
    height: 220px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .parent[data-openLayout="false"] .openContainer{
    display: none;
    max-width: 160px;
  }
  .child {
    width: 110px;
    height: 37px;
    border-radius: 5px;
    color: white;
    text-align: center;
    position: absolute;
    top: 0px;
    cursor: pointer;
  }
  .openRow p{
    display: inline-block;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-right: 5px;
    font-family: 'LemonMilkLight';
    font-size: 16px;
}
.openContainer{
  width: 150px;
  color: white;
  margin-top: 35px;
  position: absolute;
  left: -20px;
}
.time{
  position: absolute;
  left: 55px;
}
.den{
  position: relative;
  left: -45px;
  text-align: left;
}
@media screen and (max-width: 360px){
	.parent{
    width: 38%;
  }
  .parent[data-openLayout="true"] {
    width: 42%;
    left: 22%;
  }
}
@media screen and (max-width: 319px){

}
@media screen and (min-width: 320px) and (max-width: 379px){
    .parent{
        top: 13px;
    }
}
@media screen and (min-width: 380px) and (max-width: 411px){
    .parent{
        top: 13px;
    }
}
@media screen and (min-width: 412px) and (max-width: 450px){
    .parent{
        top: 13px;
    }
}
@media screen and (min-width: 451px) and (max-width: 490px){
    .parent{
        top: 13px;
    }
}
@media screen and (min-width: 491px) and (max-width: 550px){
    .parent{
        top: 13px;
    }
}
@media screen and (min-width: 551px) and (max-width: 600px){
    .parent{
        top: 13px;
    }
}
@media screen and (min-width: 601px) and (max-width: 719px){
    .parent{
        top: 13px;
    }
}
@media screen and (min-width: 720px) and (max-width: 799px){
    .parent{
        left: 61.5%;
        top: 12px !important;
    }
}
@media screen and (min-width: 800px) and (max-width: 899px){
    .parent{
        left: 65%;
        top: 12px !important;
    }
}
@media screen and (min-width: 900px) and (max-width: 1023px){
    .parent{
        left: 68%;
        top: 12px !important;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1279px){
    .parent{
        left: 72%;
        top: 12px !important;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1365px){
    .parent{
        left: 78%;
        top: 12px !important;
    }
}
@media screen and (min-width: 1366px) and (max-width: 1599px){
    .parent{
        left: 79%;
        top: 12px !important;
    }
}
@media screen and (min-width: 1600px) and (max-width: 1919px){
    .parent{
        left: 82%;
        top: 12px !important;
    }
}
@media screen and (min-width: 1920px) and (max-width: 2047px){
  .parent{
    left: 85%;
    top: 12px !important;
  }
}
@media screen and (min-width: 2048px) and (max-width: 2559px){
  .parent{
    left: 86%;
    top: 24px !important;
  }
}
@media screen and (min-width: 2560px) and (max-width: 3655px){
  .parent{
    left: 89%;
    top: 25px !important;
  }
  .onasHead{
    width: 40%;
  }
}
@media screen and (min-width: 3656px) and (max-width: 3839px){
  .parent{
    left: 92%;
    top: 25px !important;
  }
  .onasHead{
    width: 40%;
  }
}
@media screen and (min-width: 3840px) and (max-width: 4095px){
  .parent{
    left: 86%;
    top: 24px !important;
  }
}