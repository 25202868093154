.header{
    width: 100%;
    height: 300px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    left: 0px;
    top: 0px;
    background-size: cover;
    background-position: bottom center;
}
.fblogo{
    position: absolute;
    left: 84%;
    top: 66px;
}
@media screen and (min-width: 280px) and (max-width: 379px){
    .headerBack{
        transform: scale(1.30);
        bottom: none;
        top: 0px;
    }
}
@media screen and (min-width: 720px) and (max-width: 1023px){
    .header{
        background-position: bottom 17% center;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1599px){
    .headerBack{
        transform: scale(1);
        bottom: -8%;
    }
    .header{ 
        height: 500px;
        background-position: bottom 21% center;
    }
}
@media screen and (min-width: 1600px) and (max-width: 1919px){
    .headerBack{
        transform: scale(1);
        bottom: -8%;
    }
    .header{ 
        height: 598px;
        background-position: bottom 19% center;
    }
}
@media screen and (min-width: 1920px){
    .headerBack{
        transform: scale(1);
        bottom: -8%;
    }
    .header{ 
        height: 650px;
        background-position: bottom 20% center;
    }
}