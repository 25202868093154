.formular{
    width: 90%;
    position: absolute;
    left: 5%;
}
.formular input{
    display: inline-block;
    border: none;
    width: 46.3%; /*160px;*/ 
    font-family: 'LemonMilklight';
    height: 30px;
    border-radius: 5px;
    padding-left: 10px;
    padding-bottom: 5px;
    font-size: 16px;
}
.formular textarea{
    width: 96.5%; /*569px;*/
    border: none;
    font-family: 'LemonMilklight';
    margin-top: 7px;
    margin-bottom: 4px;
    padding-left: 10px;
    padding-top: 7px;
    font-size: 16px;
    border-radius: 5px;
    resize: none;
}
.right{
    position: relative;
    left: 7px;
}
.submitButton{
    position: absolute;
    left: 27%;
    top: 269px;
    color: white;
    background-color: #A9D2A5;
    font-family: 'LemonMilklight';
    font-size: 18px;
    padding: 0px !important;
    height: 40px !important;
    text-align: center;
    font-size: 18px !important;
    width: 30%;
}
.formular h3{
    text-align: center;
    font-family: 'LemonMilk';
    letter-spacing: 4px;
}
.result{
    width: 90%;
    text-align: center;
    height: 40px;
    position: absolute;
    left: 5%;
    top: 300px;
    font-family: 'LemonMilklight';
    color: rgb(131, 131, 131);
}
@media screen and (max-width: 319px){
    .formular input{
        width: 45%;
        font-size: 14px;
    }
}
@media screen and (min-width: 320px) and (max-width: 339px){
    .formular input{
        width: 44.5%;
        font-size: 14px;
        padding-left: 2%;
    }
    .right{
        left: 3%;
    }
    .submitButton{
        top: 279px;
        border-radius: 3px !important;
    }
}
@media screen and (min-width: 340px) and (max-width: 359px){
    .formular input{
        width: 44.5%;
    }
}
@media screen and (min-width: 360px) and (max-width: 399px){
    .formular input{
        width: 44.6%;
    }
}
@media screen and (min-width: 400px) and (max-width: 439px){
    .formular input{
        width: 44.6%;
    }
}
@media screen and (min-width: 440px) and (max-width: 470px){
    .formular input{
        width: 45%;
    }
}
@media screen and (min-width: 471px) and (max-width: 540px){
    .formular input{
        width: 45.6%;
    }
}
@media screen and (min-width: 541px) and (max-width: 600px){
    .formular input{
        width: 46%;
    }
}
@media screen and (min-width: 601px) and (max-width: 680px){
    .formular input{
        width: 46%;
    }
}
@media screen and (min-width: 681px) and (max-width: 719px){
    .formular input{
        width: 46%;
    }
}
@media screen and (min-width: 720px) and (max-width: 799px){
    .formular input{
        width: 46.8%;
    }
}
@media screen and (min-width: 800px) and (max-width: 1023px){
    .formular input{
        width: 46.9%; /*213px;*/ 
    }
}
@media screen and (min-width: 1024px) and (max-width: 1279px){
    .formular{
        width: 45%;
        left: 2%;
    }
    .formular input{
        width: 46.3%; /*213px;*/ 
    }
    .formular textarea{
        width: 96.5%; /*569px;*/
        height: 195px;
    }
    .submitButton{
        top: 365px;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1365px){
    .formular{
        width: 45%;
        left: 2%;
    }
    .formular input{
        width: 46.3%; /*213px;*/ 
    }
    .formular textarea{
        width: 96.5%; /*569px;*/
        height: 195px;
    }
    .submitButton{
        top: 365px;
    }
}
@media screen and (min-width: 1366px) and (max-width: 1599px){
    .formular{
        width: 45%;
        left: 2%;
    }
    .formular input{
        width: 46.7%; /*213px;*/ 
    }
    .formular textarea{
        width: 96.5%; /*569px;*/
        height: 195px;
    }
    .submitButton{
        top: 365px;
        width: 26% !important;
        left: 36%;
    }
}
@media screen and (min-width: 1600px) and (max-width: 1919px){
    .formular{
        width: 45%;
        left: 2%;
    }
    .formular input{
        width: 47%; /*213px;*/ 
    }
    .formular textarea{
        width: 96.5%; /*569px;*/
        height: 195px;
    }
    .submitButton{
        top: 365px;
        width: 26% !important;
        left: 36%;
    }
}
@media screen and (min-width: 1920px) and (max-width: 2047px){
    .formular{
        width: 45%;
        left: 2%;
    }
    .formular input{
        width: 47.1%; /*213px;*/ 
    }
    .formular textarea{
        width: 96.5%; /*569px;*/
        height: 195px;
    }
    .submitButton{
        top: 365px;
        width: 26% !important;
        left: 36%;
    }
}
@media screen and (min-width: 2048px) and (max-width: 2559px){
    .formular{
        width: 35%;
        left: 11%;
    }
    .formular input{
        width: 47.1%; /*213px;*/ 
    }
    .formular textarea{
        width: 96.5%; /*569px;*/
        height: 195px;
    }
    .submitButton{
        top: 365px;
        width: 26% !important;
        left: 36%;
    }
    .mapContainer{
        width: 35%;
        left: 53%;
    }
}
@media screen and (min-width: 2560px) and (max-width: 3655px){
    .formular{
        width: 35%;
        left: 11%;
    }
    .formular input{
        width: 47.1%; /*213px;*/ 
    }
    .formular textarea{
        width: 96.5%; /*569px;*/
        height: 195px;
    }
    .submitButton{
        top: 365px;
        width: 26% !important;
        left: 36%;
    }
    .mapContainer{
        width: 35%;
        left: 53%;
    }
}
@media screen and (min-width: 3656px) and (max-width: 3839px){
    .formular{
        width: 35%;
        left: 11%;
    }
    .formular input{
        width: 47.1%; /*213px;*/ 
    }
    .formular textarea{
        width: 96.5%; /*569px;*/
        height: 195px;
    }
    .submitButton{
        top: 365px;
        width: 26% !important;
        left: 36%;
    }
    .mapContainer{
        width: 35%;
        left: 53%;
    }
}
@media screen and (min-width: 3840px) and (max-width: 4095px){
    .formular{
        width: 35%;
        left: 11%;
    }
    .formular input{
        width: 47.1%; /*213px;*/ 
    }
    .formular textarea{
        width: 96.5%; /*569px;*/
        height: 195px;
    }
    .submitButton{
        top: 365px;
        width: 26% !important;
        left: 36%;
    }
    .mapContainer{
        width: 35%;
        left: 53%;
    }
}
@media screen and (min-width: 4096px){
    .formular{
        width: 30%;
        left: 18%;
    }
    .formular input{
        width: 47.5%; /*213px;*/ 
    }
    .formular textarea{
        width: 96.5%; /*569px;*/
        height: 195px;
    }
    .submitButton{
        top: 365px;
        width: 26% !important;
        left: 36%;
    }
    .mapContainer{
        width: 30%;
        left: 53%;
    }
}